<template>
  <v-dialog v-model="isOpen" persistent scrollable width="900">
    <v-card tile>
      <v-progress-linear
        height="10"
        striped
        color="lime"
        indeterminate
        v-show="loading"
      ></v-progress-linear>
      <v-toolbar dark color="primary" flat>
        <v-toolbar-title>
          <span>
            <v-icon left>mdi-pencil</v-icon>
            {{ $t("configurations.edit_mooring_bitt") }}
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn depressed icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" class="mt-10">
          <v-text-field
            counter="50"
            maxlength="255"
            v-if="!!itemToEdit"
            v-model="itemToEdit.code"
            required
            :label="$t('global.code')"
            :rules="codeRules"
            outlined
          />
          <v-text-field
            counter="100"
            maxlength="255"
            v-if="!!itemToEdit"
            v-model="itemToEdit.name"
            required
            :label="$t('global.name')"
            :rules="nameRules"
            outlined
          />
          <v-text-field
            v-if="!!itemToEdit"
            v-model="itemToEdit.zh"
            required
            :label="$t('global.zh')"
            :rules="zhRules"
            type="number"
            outlined
          />
          <v-text-field
            v-if="!!itemToEdit"
            v-model="itemToEdit.description"
            :label="$t('global.description')"
            outlined
          />
          <v-textarea
            v-if="!!itemToEdit"
            v-model="itemToEdit.notes"
            :label="$t('global.notes')"
            placeholder=" "
            outlined
          />
          <v-autocomplete
            v-if="!!itemToEdit"
            v-model="itemToEdit.mooring_bitt_groups"
            :items="mooringBittGroups"
            :label="$t('configurations.groups_bitts')"
            multiple
            clearable
            return-object
            item-text="name"
            item-value="id"
            outlined
          >
            <template v-slot:selection="data">
              <v-chip v-bind="data.attrs" :input-value="data.selected">
                {{ data.item.name }}
              </v-chip>
            </template>
            <template v-slot:item="data">
              <template v-if="typeof data.item !== 'object'">
                <v-list-item-content v-text="data.item"></v-list-item-content>
              </template>
              <template v-else>
                <v-list-item-content>
                  <v-list-item-title
                    v-html="data.item.name"
                  ></v-list-item-title>
                  <v-list-item-subtitle
                    v-html="data.item.code"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
          <div class="pt-5">
            <span>{{ $t('configurations.unavailable_mooring_bitts') }}</span>
            <v-switch
              v-if="!!itemToEdit"
              v-model="isUnavailable"
              :label="$t('configurations.unavailable_mooring_bitts')"
            >
              <template v-slot:label>
                <span v-if="isUnavailable">{{ $t('global.yes') }}</span>
                <span v-else>{{ $t('global.no') }}</span>
              </template>
            </v-switch>
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn depressed color="primary" text @click="save" :disabled="loading">
          {{ $t('global.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["open", "item"],
  data() {
    return {
      loading: false,
      codeRules: [(v) => !!v || this.$t('global.code_rules')],
      nameRules: [(v) => !!v || this.$t('global.name_rules')],
      zhRules: [(v) => (v ? v <= 0 || this.$t("global.positive_rule") : true)],
    };
  },
  watch: {
    isOpen(val) {
      if (val) {
        this.$store.dispatch("moorings/GET_ALL_MOORING_BITT_GROUPS");
      }
    },
  },
  computed: {
    isOpen: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("update:open", val);
      },
    },
    itemToEdit() {
      return this.item && this.item.length ? this.item[0] : null;
    },
    isUnavailable: {
      get() {
        return this.itemToEdit && this.itemToEdit.unavailable;
      },
      set(val) {
        if (this.itemToEdit) {
          this.$set(this.itemToEdit, "unavailable", val);
        }
      },
    },
    mooringBittGroups() {
      return this.$store.state.moorings.mooringBittGroupsList;
    },
  },
  methods: {
    close() {
      this.isOpen = false;
    },
    save() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.$store
          .dispatch("moorings/UPDATE_MOORING_BITT", this.itemToEdit)
          .then(() => {
            this.loading = false;
            this.close();
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>
