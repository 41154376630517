<template>
  <div class="map-container">
    <div ref="map" class="map" />
    <div class="mt-10" style="position: absolute; top: 0; right: 0">
      <v-btn
        depressed
        v-if="hasUserPermissionToViewEditOrManage('MOORING_BITTS')"
        class="ma-1"
        fab
        x-small
        dark
        title="Ativar/Desativar adição de cabeços"
        @click="toggleAdd"
        :color="this.isAddEnabled ? 'primary' : ''"
      >
        <v-icon dark> mdi-map-marker-plus-outline </v-icon>
      </v-btn>
      <v-btn
        depressed
        v-if="hasUserPermissionToViewEditOrManage('MOORING_BITTS')"
        class="ma-1"
        fab
        x-small
        dark
        title="Ativar edição de cabeços"
        @click="enterEdit"
        :color="this.isEditEnabled ? 'primary' : ''"
      >
        <v-icon dark> mdi-pencil-outline </v-icon>
      </v-btn>
    </div>
    <v-snackbar v-model="showEditMessage" width="60%" :timeout="-1">
      {{ $t("map.movement_explanation") }}
      <template v-slot:action>
        <v-btn
          depressed
          class="ma-2"
          color="error"
          small
          @click="rejectMooringBittChanges"
        >
          Rejeitar
        </v-btn>
        <v-btn
          depressed
          class="ma-2"
          color="primary"
          small
          @click="saveMooringBittChanges"
        >
          Guardar
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar v-model="showAddMessage" width="60%" :timeout="-1">
      {{ $t("map.mooring_bitt") }}
    </v-snackbar>
  </div>
</template>

<script>
import * as maptalks from "maptalks";
import mooringBittUtils from "@/helpers/map/mooringbitt_utils";
import mooringBittGroupUtils from "@/helpers/map/mooringbittgroup_utils";
const MOORINGBITTS_LAYERNAME = "mooring_bitts";
const MOORINGBITTGROUPS_LAYERNAME = "mooring_bitt_groups";
import configs from "@/helpers/configs";
export default {
  props: ["selectedMooringBitt", "mooringBitts"],
  data() {
    return {
      map: null,
      loading: false,
      drawnMooringBitts: [],
      draggedMooringBitts: {},
      addEnabled: false,
      editEnabled: false,
      showAddMessage: false,
      showEditMessage: false,
    };
  },
  computed: {
    isAddEnabled() {
      return this.addEnabled;
    },
    isEditEnabled() {
      return this.editEnabled;
    },
    locode() {
      return configs.getLocode();
    },
    selectedBitt: {
      get() {
        return this.selectedMooringBitt && this.selectedMooringBitt[0]
          ? this.selectedMooringBitt[0]
          : null;
      },
      set(val) {
        this.$emit("update:selectedMooringBitt", [val]);
      },
    },
  },
  watch: {
    mooringBitts() {
      this.processMooringBitts();
    },

    selectedBitt() {
      if (this.selectedBitt) {
        this.selectMooringBitt(this.selectedBitt);
      } else {
        this.clearDrawnMooringBittGroups();
      }
    },
  },
  mounted() {
    this.initMap();
    this.loadMooringBitts();
  },
  beforeDestroy() {
    this.clearDrawnMooringBitts();
  },
  methods: {
    initMap() {
      let center;
      //let bearing;
      let zoom;
      try {
        center = this.locode.coordinates;
        //bearing = this.locode.bearing;
        zoom = this.locode.zoom;
      } catch (e) {
        console.error(e);
        center = [-8.702487, 41.183199];
        //bearing = -38;
        zoom = 15;
      }
      this.map = new maptalks.Map(this.$refs.map, {
        center: center,
        zoom: zoom,
        seamlessZoom: true,
        maxPitch: 0,
        bearing: 0,
        hitDetect: false, // whether to enable hit detecting of layers for cursor style on this map, disable it to improve performance
        layerCanvasLimitOnInteracting: -1, // -1 to display all layers when interacting
        zoomControl: false, // add zoom control
        scaleControl: false, // add scale control
        seamlessZoom: true,
        attribution: false,
        layerSwitcherControl: {
          position: "top-left",
          // title of base layers
          baseTitle: "Base Layers",
          // title of layers
          overlayTitle: "Layers",
          // layers you don't want to manage with layer switcher
          excludeLayers: [MOORINGBITTS_LAYERNAME],
        },

        baseLayer: new maptalks.GroupTileLayer("Base TileLayer", [
          new maptalks.TileLayer("baselayer", {
            visible: false,
            urlTemplate:
              "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png",
            subdomains: ["a", "b", "c", "d"],
            attribution:
              '&copy; <a href="http://osm.org">OpenStreetMap</a> contributors, &copy; <a href="https://carto.com/">CARTO</a>',
          }),
          new maptalks.TileLayer("baselayer_nv", {
            visible: true,
            urlTemplate:
              "https://basemaps.cartocdn.com/rastertiles/voyager_nolabels/{z}/{x}/{y}.png",
            subdomains: ["a", "b", "c", "d"],
            attribution: "OSM CARTO",
          }),
          new maptalks.TileLayer("baselayer_nv_dark", {
            visible: false,
            urlTemplate:
              "https://{s}.basemaps.cartocdn.com/dark_nolabels/{z}/{x}/{y}.png",
            subdomains: ["a", "b", "c", "d"],
            attribution: "OSM CARTO DARK",
          }),
        ]),
        layers: [
          new maptalks.VectorLayer(MOORINGBITTS_LAYERNAME, []),
          new maptalks.VectorLayer(MOORINGBITTGROUPS_LAYERNAME, []),
        ],
      });
    },

    rejectMooringBittChanges() {
      this.toggleEdit();
      this.loadMooringBitts();
    },

    saveMooringBittChanges() {
      if (this.draggedMooringBitts) {
        let bittsToUpdate = [];
        for (let id in this.draggedMooringBitts) {
          let coords = this.draggedMooringBitts[id].getCoordinates();
          let wkt = "POINT(" + coords.x + " " + coords.y + ")";
          bittsToUpdate.push({
            id: id,
            map_geom: wkt,
          });
        }
        this.$store
          .dispatch("moorings/UPDATE_MOORING_BITTS", bittsToUpdate)
          .then(() => {
            this.toggleEdit();
          });
      }
    },

    handleMooringBittDragged(e) {
      if (e && e.domEvent) {
        e.domEvent.preventDefault();
        e.domEvent.stopPropagation();
      }
      this.draggedMooringBitts[e.target.properties.id] = e.target;
    },

    handleMooringBittClicked(e) {
      if (e && e.domEvent) {
        e.domEvent.preventDefault();
        e.domEvent.stopPropagation();
      }
      if (e.target) {
        let found = this.mooringBitts.find((mb) => {
          return mb.id == e.target.properties.id;
        });
        if (found) {
          this.selectedBitt = found;
        } else {
          this.selectedBitt = [];
        }
        this.clearDrawnMooringBittGroups();
        if (found && found.mooring_bitt_groups) {
          //loop each group of this bitt
          found.mooring_bitt_groups.forEach((mbg) => {
            this.drawMooringBittGroup(mbg);
          });
        }
      }
    },

    handleMooringBittAdded(bitt) {
      let drawnBitt = mooringBittUtils.drawMooringBitt(bitt);
      if (drawnBitt) {
        drawnBitt.addTo(this.map.getLayer(MOORINGBITTS_LAYERNAME));
        drawnBitt.on("click", this.handleMooringBittClicked);
        drawnBitt.setInfoWindow({
          content:
            '<div class="mooringbitt-notes">' +
            "<small>" +
            bitt.Code +
            "</small>" +
            "<p>" +
            bitt.Name +
            "</p>" +
            (bitt.Notes ? "<p>" + bitt.Notes + "</p>" : "") +
            "</div>",
          autoPan: false,
          dy: -10,
          custom: true,
          autoOpenOn: "click", //set to null if not to open when clicking on marker
          autoCloseOn: "click",
        });
        drawnBitt.config("draggable", this.editEnabled);
        bitt._drawnObject = drawnBitt;
      }
    },

    loadMooringBitts() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("moorings/GET_ALL_MOORING")
          .then(() => {
            this.loading = false;
            resolve();
          })
          .catch((err) => {
            this.loading = false;
            reject(err);
          });
      });
    },

    processMooringBitts() {
      this.clearDrawnMooringBitts();
      if (this.mooringBitts) {
        this.drawnMooringBitts = this.mooringBitts.map((bitt) => {
          return {
            Id: bitt.id,
            Code: bitt.code,
            Name: bitt.name,
            MapGeom: bitt.map_geom,
            Unavailable: bitt.unavailable,
            Notes: bitt.notes,
            MooringBittGroups: bitt.mooring_bitt_groups,
            zh: bitt.zh,
          };
        });
        this.drawnMooringBitts.forEach(this.handleMooringBittAdded.bind(this));
      }
    },

    clearDrawnMooringBitts() {
      if (this.drawnMooringBitts) {
        for (let i = this.drawnMooringBitts.length - 1; i >= 0; i--) {
          let bitt = this.drawnMooringBitts[i];
          if (bitt && bitt._drawnObject) {
            bitt._drawnObject.off("click", this.handleMooringBittClicked);
            bitt._drawnObject.remove();
          }
          this.drawnMooringBitts.splice(i, 1);
        }
      }
      this.draggedMooringBitts = {};
      this.clearDrawnMooringBittGroups();
    },

    mooringBittsFilter(item, queryText) {
      const itemText = item.name.toLowerCase();
      const itemCode = item.code.toLowerCase();
      const searchText = queryText.toLowerCase();
      return (
        itemText.indexOf(searchText) > -1 || itemCode.indexOf(searchText) > -1
      );
    },

    selectMooringBitt(bitt) {
      if (this.drawnMooringBitts) {
        let found = this.drawnMooringBitts.find((dBitt) => {
          if (dBitt && dBitt._drawnObject && bitt) {
            return dBitt._drawnObject.properties.id == bitt.id;
          }
        });
        if (found && found._drawnObject) {
          this.map.animateTo({
            center: found._drawnObject.getCoordinates(),
            zoom: 18,
          });
          found._drawnObject.openInfoWindow();
        }
      }
    },

    toggleAdd() {
      if (this.isEditEnabled) {
        this.toggleEdit();
      }
      this.addEnabled = !this.addEnabled;
      this.showAddMessage = this.addEnabled;
      if (this.map) {
        this.map.off("click", this.handleMapClick);
        if (this.addEnabled) this.map.on("click", this.handleMapClick);
      }
    },

    handleMapClick(e) {
      if (e && e.coordinate) {
        let newDrawnBitt = {
          Id: 0,
          Code: "",
          Name: "Novo cabeço",
          MapGeom: "POINT(" + e.coordinate.x + " " + e.coordinate.y + ")",
          Unavailable: false,
          Notes: "",
          MooringBittGroups: [],
          zh: null,
        };
        this.handleMooringBittAdded(newDrawnBitt);
        this.$emit("bittAdded", newDrawnBitt);
      }
    },

    enterEdit() {
      if (!this.editEnabled) {
        this.toggleEdit();
      }
    },

    toggleEdit() {
      if (this.isAddEnabled) {
        this.toggleAdd();
      }
      this.editEnabled = !this.editEnabled;
      this.showEditMessage = this.editEnabled;
      if (this.drawnMooringBitts) {
        this.drawnMooringBitts.forEach((drawnBitt) => {
          if (drawnBitt && drawnBitt._drawnObject) {
            drawnBitt._drawnObject.config("draggable", this.editEnabled);
            if (this.editEnabled) {
              drawnBitt._drawnObject.on(
                "dragend",
                this.handleMooringBittDragged
              );
            } else {
              drawnBitt._drawnObject.off(
                "dragend",
                this.handleMooringBittDragged
              );
            }
          }
        });
      }
    },

    clearDrawnMooringBittGroups() {
      this.map.getLayer(MOORINGBITTGROUPS_LAYERNAME).clear();
    },

    drawMooringBittGroup(group) {
      if (group) {
        //Get the mooring bitts that belong to this specific group
        let bitts = this.mooringBitts.filter((mb) => {
          return (
            mb.mooring_bitt_groups &&
            mb.mooring_bitt_groups.some((mbg) => {
              return mbg.id == group.id;
            })
          );
        });
        //if we can, draw the group(s) that this mooring belongs to
        try {
          let drawnGroup = mooringBittGroupUtils.drawMooringBittGroupFromBitts(
            group,
            bitts
          );
          if (drawnGroup) {
            drawnGroup.addTo(this.map.getLayer(MOORINGBITTGROUPS_LAYERNAME));
          }
        } catch (ex) {
          console.error(ex);
        }
      }
    },
  },
};
</script>

<style scoped>
.map,
.map-container {
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 150px);
  overflow: hidden;
  position: relative;
}

.map-bitts-selector {
  position: absolute;
  top: 0;
  left: 0;
  margin: 10px;
  padding: 10px;
  background: rgba(255, 255, 255, 0.75);
  -webkit-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75);
}
</style>
<style>
.mooringbitt-notes {
  white-space: nowrap;
  font-size: 0.65rem;
  border: 1px solid black;
  font-weight: bold;
  padding: 5px;
  background: aliceblue;
  text-transform: uppercase;
  text-align: center;
}

.mooringbitt-notes p {
  margin: 0;
}
</style>
<style scoped src="@/../node_modules/maptalks/dist/maptalks.css"></style>
