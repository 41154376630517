<template>
  <MooringBitts />
</template>

<script>
import MooringBitts from "@/components/Configurations/MooringBitts";

export default {
  components: {
    MooringBitts,
  },
};
</script>
