import * as maptalks from "maptalks";
import * as wkt from "wkt";
import Rope from "@/helpers/map/rope";

const MOORING_BITT_IN_USE_COLOR = "#1976d2";
/**
 * MOORING BITT DRAW UTILITIES
 */
export default {
  drawMooringBitt(bitt) {
    var bittMarker = null;
    if (bitt && bitt.MapGeom) {
      let location = wkt.parse(bitt.MapGeom);
      if (location.type == "Point") {
        bittMarker = new maptalks.Marker(location.coordinates, {
          properties: {
            id: bitt.Id,
            shortenedCode: bitt.Code
              ? bitt.Code.slice(bitt.Code.length - 2)
              : "",
            backgroundColor: bitt.Unavailable
              ? "#d32f2f"
              : bitt.InUse
              ? MOORING_BITT_IN_USE_COLOR
              : window.VUE_APP_DEFAULT_BITTS_COLOR,
            borderColor: bitt.Notes ? "#ffff00" : "black",
            textColor: bitt.Notes ? "#ffff00" : "#ffffff",
            code: bitt.Code,
            name: bitt.Name,
            notes: bitt.Notes,
            unavailable: bitt.Unavailable,
            inUse: bitt.InUse,
          },
        });
        bittMarker.updateSymbol(this.getMooringBittStyle());
        if (bitt.Notes) {
          bittMarker.on("dblclick", (e) => {
            if (e && e.domEvent) {
              e.domEvent.preventDefault();
              e.domEvent.stopPropagation();
            }
            if (e && e.target) {
              e.target.openInfoWindow();
            }
          });
          bittMarker.setInfoWindow({
            content:
              '<div class="mooringbitt-notes">' +
              "<small>" +
              bitt.Code +
              "</small>" +
              "<p>" +
              bitt.Notes +
              "</p>" +
              "</div>",
            autoPan: false,
            dy: -10,
            custom: true,
            autoOpenOn: null, //set to null if not to open when clicking on marker
            autoCloseOn: "click",
          });
        }
      }
    }
    return bittMarker;
  },

  drawMooringBitts(map, bitts) {
    var drawnBitts = [];
    if (bitts) {
      bitts.forEach((bitt) => {
        drawnBitts.push(this.drawMooringBitt(map, bitt));
      });
    }
    return drawnBitts;
  },

  getMooringBittStyle() {
    return {
      textFaceName: "Nunito",
      textName: "{shortenedCode}", //value from name in geometry's properties
      textWeight: "bold", //'bold', 'bold'
      textStyle: "normal", //'italic', 'oblique'
      textSize: {
        stops: [
          [5, 0],
          [10, 0],
          [15, 0],
          [16, 7],
          [17, 8],
        ],
      },
      textFill: {
        // use heat value in property
        property: "textColor",
        type: "identity",
      },
      textOpacity: 1,
      textLineSpacing: 0,
      textDx: 0,
      textDy: 0,
      textHorizontalAlignment: "middle", //left | middle | right | auto
      textVerticalAlignment: "middle", // top | middle | bottom | auto
      textAlign: "center", //left | right | center | auto,
      markerType: "ellipse",
      markerWidth: {
        stops: [
          [5, 0],
          [10, 4],
          [15, 5],
          [16, 10],
          [17, 14],
        ],
      },
      markerHeight: {
        stops: [
          [5, 0],
          [10, 4],
          [15, 5],
          [16, 10],
          [17, 14],
        ],
      },
      markerFill: {
        // use heat value in property
        property: "backgroundColor",
        type: "identity",
      },
      markerFillOpacity: 1,
      markerLineColor: {
        // use heat value in property
        property: "borderColor",
        type: "identity",
      },
      markerLineWidth: 1,
    };
  },

  getMooringRopeStyle() {
    return {
      symbol: [
        {
          lineColor: "DarkSlateGrey",
          lineWidth: 2,
          "lineOpacity ": {
            stops: [
              [14, 0],
              [14, 1],
            ],
          },
        },
      ],
    };
  },

  drawBowMooringRope(drawnShip, mooringBitt) {
    let connector = new Rope(drawnShip, mooringBitt, {
      showOn: "always", //'moving', 'click', 'mouseover', 'always'
      arrowPlacement: "point", // 'vertex-last', //vertex-first, vertex-last, vertex-firstlast, point
      symbol: this.getMooringRopeStyle().symbol,
    });
    return connector;
  },

  drawSternMooringRope(drawnShip, mooringBitt) {
    let connector = new Rope(drawnShip, mooringBitt, {
      showOn: "always", //'moving', 'click', 'mouseover', 'always'
      arrowPlacement: "point", // 'vertex-last', //vertex-first, vertex-last, vertex-firstlast, point
      symbol: this.getMooringRopeStyle().symbol,
    });
    return connector;
  },
};
