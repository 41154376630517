<template>
  <div class="ma-5">
    <v-alert
      v-if="hasUserPermissionToViewEditOrManage('MOORING_BITTS')"
      color="blue-grey"
      text
      icon="mdi-information-outline"
    >
      {{ $t("configurations.mooring_bitt_groups_information") }}
    </v-alert>

    <v-btn
      v-if="hasUserPermissionToViewEditOrManage('MOORING_BITTS')"
      class="mb-5"
      color="primary"
      @click="addGroup"
      block
      large
      depressed
    >
      <v-icon left>mdi-plus-box-multiple</v-icon>
      Novo grupo
    </v-btn>

    <v-data-table
      :loading="loading"
      :search="searchTerm"
      :page.sync="page"
      :headers="headers"
      :items="mooringBittGroups"
      :items-per-page="-1"
      :custom-filter="customFilter"
      item-key="id"
      fixed-header
      sort-by="code"
      sort-desc
      must-sort
      :hide-default-footer="true"
      height="calc(100vh - 480px)"
    >
      <template v-slot:top>
        <v-text-field
          v-model="searchTerm"
          single-line
          :label="$t('configurations.search_group')"
          prepend-inner-icon="mdi-magnify"
          outlined
        />
      </template>
      <template
        v-if="hasUserPermissionToViewEditOrManage('MOORING_BITTS')"
        v-slot:item.actions="{ item }"
      >
        <v-menu bottom absolute>
          <template v-slot:activator="{ on, attrs }">
            <v-btn depressed icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              v-if="hasUserPermissionToManage('MOORING_BITTS')"
              @click="removeGroup(item)"
            >
              <v-list-item-icon class="mr-2">
                <v-icon>mdi-delete</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t("global.remove") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="hasUserPermissionToViewEditOrManage('MOORING_BITTS')"
              @click="editGroup(item)"
            >
              <v-list-item-icon class="mr-2">
                <v-icon>mdi-pencil</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t("global.edit") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:item.vector="{ item }">
        <div>
          <v-chip
            outlined
            v-if="item.vector == 1"
            small
            dark
            color="primary darken-2"
            >{{ $t("global.normal_vector") }}</v-chip
          >
          <v-chip
            outlined
            v-if="item.vector == -1"
            small
            dark
            color="red darken-2"
            >{{ $t("global.inverted_vector") }}</v-chip
          >
        </div>
      </template>
      <template v-slot:item.code="{ item }">
        <b class="font-weight-black">{{ item.code }}</b>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: ["search"],
  data() {
    return {
      loading: true,
      page: 1,
      headers: [
        {
          text: "",
          value: "actions",
          sortable: false,
        },
        {
          text: this.$t("global.code"),
          value: "code",
        },
        {
          text: this.$t("global.name"),
          value: "name",
        },
        {
          text: this.$t("global.vector"),
          value: "vector",
        },
      ],
    };
  },

  computed: {
    mooringBittGroups() {
      return this.$store.state.moorings.mooringBittGroupsList;
    },
    searchTerm: {
      get() {
        return this.search;
      },
      set(val) {
        this.$emit("update:search", val);
      },
    },
  },

  mounted() {
    this.loading = true;
    this.$store.dispatch("moorings/GET_ALL_MOORING_BITT_GROUPS").then(() => {
      this.loading = false;
    });
  },
  methods: {
    customFilter(_, search, item) {
      if (!search || search == "") return true;

      let searchWords = search.toLowerCase().split(",");

      for (let i = 0; i < searchWords.length; i++) {
        if (
          item.code &&
          item.code.toLowerCase().indexOf(searchWords[i]) !== -1
        ) {
          return true;
        }

        if (
          item.name &&
          item.name.toLowerCase().indexOf(searchWords[i]) !== -1
        ) {
          return true;
        }
      }

      return false;
    },
    addGroup() {
      this.$emit("add");
    },
    editGroup(group) {
      this.$emit("edit", group);
    },
    removeGroup(group) {
      this.$emit("remove", group);
    },
  },
};
</script>
