<template>
  <v-dialog v-model="isOpen" persistent width="900">
    <v-card tile>
      <v-progress-linear
        height="10"
        striped
        color="lime"
        indeterminate
        v-show="loading"
      ></v-progress-linear>
      <v-toolbar dark color="primary" flat>
        <v-toolbar-title>
          <span>
            <v-icon left>mdi-add</v-icon
            >{{ $t("configurations.create_mooring_bitts_group") }}
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn depressed icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" class="mt-10">
          <v-text-field
            counter="255"
            maxlength="255"
            v-model="code"
            required
            :label="$t('global.code')"
            :rules="codeRules"
            outlined
          />
          <v-text-field
            v-model="name"
            required
            :label="$t('global.name')"
            :rules="nameRules"
            outlined
          />
          <v-select
            v-model="port_locode"
            :items="locodes"
            required
            :label="$t('global.port_scale')"
            item-text="name"
            item-value="code"
            outlined
          />
          <v-select
            v-model="vector"
            :items="vector_options"
            required
            :label="$t('global.vector')"
            item-text="text"
            item-value="value"
            outlined
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn depressed color="primary" text @click="save" :disabled="loading">
          {{ $t("global.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import configs from "@/helpers/configs";
export default {
  props: ["open"],
  data() {
    return {
      loading: false,
      codeRules: [(v) => !!v || this.$t("global.code_rules")],
      nameRules: [(v) => !!v || this.$t("global.name_rules")],
      code: "",
      name: "",
      locodes: configs.getLocodes(),
      port_locode: null,
      vector: 1,
      vector_options: [
        {
          text: this.$t("global.normal_vector"),
          value: 1,
        },
        {
          text: this.$t("global.inverted_vector"),
          value: -1,
        },
      ],
    };
  },
  computed: {
    isOpen: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("update:open", val);
      },
    },
    mooringBittGroups() {
      return this.$store.state.moorings.mooringBittGroupsList;
    },

    currentLocode() {
      return this.$store.state.user.account.locode;
    },
  },
  watch: {
    open(val) {
      if (val) {
        this.code = "";
        this.name = "";
        this.port_locode = this.currentLocode.code;
        this.vector = 1;
      }
    },
  },
  methods: {
    close() {
      this.isOpen = false;
    },
    save() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.$store
          .dispatch("moorings/CREATE_MOORING_BITT_GROUP", {
            code: this.code,
            name: this.name,
            port_locode: this.port_locode,
            vector: this.vector,
          })
          .then(() => {
            this.loading = false;
            this.close();
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>
