<template>
  <v-dialog v-model="isOpen" persistent scrollable width="900">
    <v-card tile>
      <v-progress-linear
        height="10"
        striped
        color="lime"
        indeterminate
        v-show="loading"
      ></v-progress-linear>
      <v-toolbar dark color="primary" flat>
        <v-toolbar-title>
          <span>
            <v-icon left>mdi-add</v-icon
            >{{ $t("configurations.create_mooring_bitts") }}
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn depressed icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" class="mt-10">
          <v-text-field
            counter="50"
            maxlength="255"
            v-if="!!itemToAdd"
            v-model="itemToAdd.Code"
            required
            :label="$t('global.code')"
            :rules="codeRules"
            outlined
          />
          <v-text-field
            counter="100"
            maxlength="255"
            v-if="!!itemToAdd"
            v-model="itemToAdd.Name"
            required
            :label="$t('global.name')"
            :rules="nameRules"
            outlined
          />
          <v-text-field
            v-if="!!itemToAdd"
            v-model="itemToAdd.zh"
            required
            :label="$t('global.zh')"
            :rules="zhRules"
            type="number"
            outlined
          />
          <v-text-field
            v-if="!!itemToAdd"
            v-model="itemToAdd.Description"
            :label="$t('global.description')"
            outlined
          />
          <v-textarea
            v-if="!!itemToAdd"
            v-model="itemToAdd.Notes"
            :label="$t('global.notes')"
            placeholder=" "
            outlined
          />
          <v-autocomplete
            v-if="!!itemToAdd"
            v-model="itemToAdd.MooringBittGroups"
            :items="mooringBittGroups"
            :label="$t('configurations.group_mooring_bitts')"
            multiple
            clearable
            return-object
            item-text="name"
            item-value="id"
          >
            <template v-slot:selection="data">
              <v-chip v-bind="data.attrs" :input-value="data.selected">
                {{ data.item.name }}
              </v-chip>
            </template>
            <template v-slot:item="data">
              <template v-if="typeof data.item !== 'object'">
                <v-list-item-content v-text="data.item"></v-list-item-content>
              </template>
              <template v-else>
                <v-list-item-content>
                  <v-list-item-title
                    v-html="data.item.name"
                  ></v-list-item-title>
                  <v-list-item-subtitle
                    v-html="data.item.code"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
          <div class="pt-5">
            <span>Cabeço indisponível</span>
            <v-switch
              v-if="!!itemToAdd"
              v-model="isUnavailable"
              :label="$t('configurations.unavailable_mooring_bitts')"
            >
              <template v-slot:label>
                <span v-if="isUnavailable">{{ $t("global.yes") }}</span>
                <span v-else>{{ $t("global.no") }}</span>
              </template>
            </v-switch>
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn depressed color="primary" text @click="save" :disabled="loading">
          {{ $t("global.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["open", "item"],
  data() {
    return {
      loading: false,
      codeRules: [(v) => !!v || this.$t("global.code_rules")],
      nameRules: [(v) => !!v || this.$t("global.name_rules")],
      zhRules: [(v) => (v ? v <= 0 || this.$t("global.positive_rule") : true)],
    };
  },
  watch: {
    isOpen(val) {
      if (val) {
        this.$store.dispatch("moorings/GET_ALL_MOORING_BITT_GROUPS");
      }
    },
  },
  computed: {
    isOpen: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("update:open", val);
      },
    },
    itemToAdd() {
      return this.item;
    },

    isUnavailable: {
      get() {
        return this.itemToAdd && this.itemToAdd.Unavailable == true;
      },
      set(val) {
        if (this.itemToAdd) {
          if (val) {
            this.itemToAdd.Unavailable = true;
          } else {
            this.itemToAdd.Unavailable = false;
          }
        }
      },
    },
    mooringBittGroups() {
      return this.$store.state.moorings.mooringBittGroupsList;
    },
  },
  methods: {
    close() {
      this.isOpen = false;
    },
    save() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.$store
          .dispatch("moorings/CREATE_MOORING_BITT", {
            code: this.item.Code,
            name: this.item.Name,
            zh: this.item.zh,
            description: this.item.Description,
            notes: this.item.Notes,
            map_geom: this.item.MapGeom,
            unavailable: this.item.Unavailable,
            mooring_bitt_groups: this.item.MooringBittGroups,
          })
          .then(() => {
            this.loading = false;
            this.close();
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>
