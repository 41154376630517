<template>
  <div class="ma-4">
    <div class="d-flex flex-wrap">
      <div class="ma-0 pa-0">
        <p class="text-h5 font-weight-black mb-0">
          {{ $t("configurations.mooring_bitts") }}
        </p>
        <p class="text-caption">
          {{ $t("configurations.mooring_bitts_information") }}
        </p>
      </div>
      <v-spacer></v-spacer>

      <div
        v-if="hasUserPermissionToViewOrManage('MOORING_BITTS')"
        class="ml-auto"
      >
        <div v-if="$vuetify.breakpoint.lgAndDown">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="black--text"
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                depressed
              >
                <v-icon color="white"> mdi-dots-vertical </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-if="hasUserPermissionToViewEditOrManage('MOORING_BITTS')"
                @click="openImportDialog"
              >
                <v-list-item-avatar class="mr-2">
                  <v-icon left> mdi-upload</v-icon>
                </v-list-item-avatar>
                <v-list-item-title>
                  {{ $t("global.import") }}
                </v-list-item-title>
              </v-list-item>

              <v-list-item @click="openExportDialog">
                <v-list-item-avatar class="mr-2">
                  <v-icon left>mdi-download</v-icon>
                </v-list-item-avatar>
                <v-list-item-title>
                  {{ $t("global.export") }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div v-else>
          <v-btn
            v-if="hasUserPermissionToViewEditOrManage('MOORING_BITTS')"
            color="primary"
            @click="openImportDialog"
            depressed
          >
            <v-icon left>mdi-upload</v-icon>
            {{ $t("global.import") }}
          </v-btn>

          <v-btn
            class="ml-2"
            color="primary"
            @click="openExportDialog"
            depressed
          >
            <v-icon left>mdi-download</v-icon>
            {{ $t("global.export") }}
          </v-btn>
        </div>
      </div>
    </div>

    <v-row class="fill-height">
      <v-col cols="6" sm="6" md="6" lg="6">
        <Map
          :selectedMooringBitt.sync="selectedMooringBitt"
          :mooringBitts.sync="mooringBitts"
          @bittAdded="handleMooringBittAdded"
        />
      </v-col>
      <v-col cols="6" sm="6" md="6" lg="6">
        <v-card tile height="100%" outlined>
          <v-tabs v-model="tab" background-color="primary" dark>
            <v-tabs-slider color="yellow"></v-tabs-slider>
            <v-tab key="tab_mb"
              ><v-icon left>mdi-window-shutter-open</v-icon
              >{{ $t("configurations.bitts") }}</v-tab
            >
            <v-tab key="tab_mbg"
              ><v-icon left>mdi-circle-multiple</v-icon
              >{{ $t("configurations.group_bitts") }}</v-tab
            >
          </v-tabs>
          <v-card-text class="ma-0 pa-0">
            <v-tabs-items v-model="tab">
              <v-tab-item key="tab_mb">
                <v-alert
                  v-if="hasUserPermissionToViewEditOrManage('MOORING_BITTS')"
                  class="ma-4"
                  color="blue-grey"
                  text
                  icon="mdi-information-outline"
                >
                  {{ $t("configurations.mooring_bitts_note_part_one") }}
                  <v-icon> mdi-map-marker-plus-outline </v-icon>
                  {{ $t("configurations.mooring_bitts_note_part_two") }}
                  <v-icon> mdi-pencil-outline </v-icon>
                </v-alert>
                <MooringBittsList
                  :selectedMooringBitt.sync="selectedMooringBitt"
                  :mooringBitts.sync="mooringBitts"
                  :page.sync="page"
                  :search.sync="search"
                  @remove="removeBitt"
                  @edit="editBitt"
                  :key="componentMBKey"
                />
              </v-tab-item>
              <v-tab-item key="tab_mbg">
                <MooringBittGroupsList
                  @add="addGroup"
                  @remove="removeGroup"
                  @edit="editGroup"
                  :key="componentMBGKey"
                  :search.sync="searchGroup"
                />
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    
    <CreateMooringBittDialog
      :open.sync="openAddMooringBittDialog"
      :item="addedMooringBitt"
    />
    <EditMooringBittDialog
      :open.sync="openEditMooringBittDialog"
      :item="selectedMooringBitt"
    />
    <DeleteMooringBittDialog
      :open.sync="openDeleteMooringBittDialog"
      :item="selectedMooringBitt"
    />

    <CreateMooringBittGroupDialog
      :open.sync="openAddMooringBittGroupDialog"
      :item="this.selectedMooringBittGroup"
    />
    <EditMooringBittGroupDialog
      :open.sync="openEditMooringBittGroupDialog"
      :item="this.selectedMooringBittGroup"
    />
    <DeleteMooringBittGroupDialog
      :open.sync="openDeleteMooringBittGroupDialog"
      :item="this.selectedMooringBittGroup"
    />

    <ImportMooringBittsDialog :open.sync="openImportMooringBittsDialog" />

    <ExportMooringBittsDialog :open.sync="openExportMooringBittsDialog" />
  </div>
</template>

<script>
import Map from "./MooringBitts/Map";
import MooringBittsList from "./MooringBitts/MooringBittsList";
import MooringBittGroupsList from "./MooringBitts/MooringBittGroupsList";

//Mooring bitts
import CreateMooringBittDialog from "./MooringBitts/CreateMooringBittDialog";
import EditMooringBittDialog from "./MooringBitts/EditMooringBittDialog";
import DeleteMooringBittDialog from "./MooringBitts/DeleteMooringBittDialog";

//Mooring bitt groups
import CreateMooringBittGroupDialog from "./MooringBitts/CreateMooringBittGroupDialog";
import EditMooringBittGroupDialog from "./MooringBitts/EditMooringBittGroupDialog";
import DeleteMooringBittGroupDialog from "./MooringBitts/DeleteMooringBittGroupDialog";

import ImportMooringBittsDialog from "./MooringBitts/ImportMooringBittsDialog";
import ExportMooringBittsDialog from "./MooringBitts/ExportMooringBittsDialog";

export default {
  components: {
    Map,
    MooringBittsList,
    MooringBittGroupsList,
    CreateMooringBittDialog,
    EditMooringBittDialog,
    DeleteMooringBittDialog,
    CreateMooringBittGroupDialog,
    EditMooringBittGroupDialog,
    DeleteMooringBittGroupDialog,
    ImportMooringBittsDialog,
    ExportMooringBittsDialog,
  },
  computed: {
    mooringBitts() {
      return this.$store.state.moorings.mooringsList;
    },
  },
  watch: {
    selectedMooringBitt() {
      if (
        !this.openEditMooringBittDialog &&
        !this.openDeleteMooringBittDialog
      ) {
        if (this.selectedMooringBitt && this.selectedMooringBitt[0]) {
          this.search = this.selectedMooringBitt[0].code;
          this.page = 1;

          if (
            this.selectedMooringBitt[0] &&
            this.selectedMooringBitt[0].mooring_bitt_groups.length > 0
          ) {
            this.searchGroup = this.selectedMooringBitt[0].mooring_bitt_groups
              .map((group) => {
                return group.code;
              })
              .join(",");
          }
        } else {
          this.search = "";
        }
      }
    },
    openAddMooringBittDialog(val) {
      if (val) {
        this.openEditMooringBittDialog = false;
        this.openDeleteMooringBittDialog = false;
      } else {
        if (this.addedMooringBitt && this.addedMooringBitt._drawnObject) {
          this.addedMooringBitt._drawnObject.remove();
        }
        this.addedMooringBitt = null;
        this.forceMBRerender();
      }
    },

    openEditMooringBittDialog(val) {
      if (val) {
        this.openAddMooringBittDialog = false;
        this.openDeleteMooringBittDialog = false;
      } else {
        this.forceMBRerender();
      }
    },

    openDeleteMooringBittDialog(val) {
      if (val) {
        this.openAddMooringBittDialog = false;
        this.openEditMooringBittDialog = false;
      } else {
        this.selectedMooringBitt = [];
        this.forceMBRerender();
      }
    },

    openAddMooringBittGroupDialog(val) {
      if (val == false) this.forceMBGRerender();
    },
    openEditMooringBittGroupDialog(val) {
      if (val == false) this.forceMBGRerender();
    },
    openDeleteMooringBittGroupDialog(val) {
      if (val == false) this.forceMBGRerender();
    },
  },
  data() {
    return {
      componentMBKey: 0,
      componentMBGKey: 100,
      tab: null,
      search: "",
      searchGroup: "",
      page: 1,
      selectedMooringBitt: [],
      openEditMooringBittDialog: false,
      openAddMooringBittDialog: false,
      openDeleteMooringBittDialog: false,
      openAddMooringBittGroupDialog: false,
      openEditMooringBittGroupDialog: false,
      openDeleteMooringBittGroupDialog: false,
      openImportMooringBittsDialog: false,
      openExportMooringBittsDialog: false,
      selectedMooringBittGroup: null,
      addedMooringBitt: null,
    };
  },
  methods: {
    handleMooringBittAdded(bitt) {
      this.openAddMooringBittDialog = true;
      this.addedMooringBitt = bitt;
    },
    removeBitt(bitt) {
      this.selectedMooringBitt = [bitt];
      this.openDeleteMooringBittDialog = true;
    },
    editBitt(bitt) {
      this.openEditMooringBittDialog = true;
      this.selectedMooringBitt = [bitt];
    },

    addGroup() {
      this.openAddMooringBittGroupDialog = true;
    },
    removeGroup(group) {
      this.openDeleteMooringBittGroupDialog = true;
      this.selectedMooringBittGroup = group;
    },
    editGroup(group) {
      this.openEditMooringBittGroupDialog = true;
      this.selectedMooringBittGroup = group;
    },
    forceMBRerender() {
      this.componentMBKey += 1;
    },
    forceMBGRerender() {
      this.componentMBGKey += 1;
    },

    openImportDialog() {
      this.openImportMooringBittsDialog = true;
    },
    openExportDialog() {
      this.openExportMooringBittsDialog = true;
    },
  },
};
</script>
