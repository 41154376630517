<template>
  <div class="ma-5">
    <v-data-table
      :loading="loading"
      v-model="selectedBitt"
      :search="searchTerm"
      :page.sync="pageIndex"
      :headers="headers"
      :items="mooringBitts"
      single-select
      :items-per-page="-1"
      item-key="id"
      fixed-header
      @click:row="rowClick"
      sort-by="code"
      sort-desc
      must-sort
      :custom-filter="customFilter"
      :hide-default-footer="true"
      height="calc(100vh - 420px)"
      dense
    >
      <template v-slot:top>
        <v-text-field
          v-model="searchTerm"
          single-line
          :label="$t('configurations.search_mooring_bitt')"
          prepend-inner-icon="mdi-magnify"
          outlined
        />
      </template>
      <template
        v-if="hasUserPermissionToViewEditOrManage('MOORING_BITTS')"
        v-slot:item.actions="{ item }"
      >
        <v-menu bottom absolute>
          <template v-slot:activator="{ on, attrs }">
            <v-btn depressed icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              v-if="hasUserPermissionToManage('MOORING_BITTS')"
              @click="removeBitt(item)"
            >
              <v-list-item-icon class="mr-2">
                <v-icon>mdi-delete</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t("global.remove") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="hasUserPermissionToViewEditOrManage('MOORING_BITTS')"
              @click="editBitt(item)"
            >
              <v-list-item-icon class="mr-2">
                <v-icon>mdi-pencil</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t("global.edit") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:item.mooring_bitt_groups="{ item }">
        <v-chip-group>
          <v-chip
            dense
            v-for="bittGroup in item.mooring_bitt_groups"
            :key="bittGroup.id"
            small
            outlined
            >{{ bittGroup.name }}</v-chip
          >
        </v-chip-group>
      </template>
      <template v-slot:item.code="{ item }">
        <b class="font-weight-black">{{ item.code }}</b>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: ["selectedMooringBitt", "mooringBitts", "page", "search"],
  data() {
    return {
      headers: [
        {
          text: "",
          value: "actions",
          sortable: false,
        },
        {
          text: this.$t("global.code"),
          value: "code",
        },
        {
          text: this.$t("global.name"),
          value: "name",
        },
        {
          text: this.$t("configurations.groups_of_group_bitts"),
          value: "mooring_bitt_groups",
          sort: (a, b) => {
            if (a instanceof Array) {
              a = a[0];
            }
            if (b instanceof Array) {
              b = b[0];
            }
            if (a && b) {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
            }
            return 0;
          },
        },
        {
          text: this.$t("global.zh"),
          value: "zh",
        },
      ],
    };
  },

  mounted() {
    this.$store.dispatch("moorings/GET_ALL_MOORING");
  },

  computed: {
    loading() {
      return this.$store.state.moorings.loading;
    },
    selectedBitt: {
      get() {
        return this.selectedMooringBitt;
      },
      set(val) {
        this.$emit("update:selectedMooringBitt", val);
      },
    },

    searchTerm: {
      get() {
        return this.search;
      },
      set(val) {
        this.$emit("update:search", val);
      },
    },

    pageIndex: {
      get() {
        return this.page;
      },
      set(val) {
        this.$emit("update:page", val);
      },
    },
  },

  methods: {
    customFilter(value, search, item) {
      if (!search || search == "") return true;
      if (
        item.code &&
        item.code.toLowerCase().indexOf(search.toLowerCase()) !== -1
      )
        return true;
      if (
        item.name &&
        item.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
      )
        return true;
      if (
        item &&
        item.mooring_bitt_groups &&
        item.mooring_bitt_groups instanceof Array
      ) {
        return item.mooring_bitt_groups.some((mbg) => {
          return (
            mbg &&
            mbg.name &&
            mbg.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
          );
        });
      }
      return false;
    },
    rowClick: function (item, row) {
      row.select(true);
    },
    removeBitt(bitt) {
      this.$emit("remove", bitt);
    },
    editBitt(bitt) {
      this.$emit("edit", bitt);
    },
  },
};
</script>
