import * as maptalks from "maptalks";
import * as wkt from 'wkt';
import * as turf from '@turf/turf';
/**
 * MOORING BITT GROUP DRAW UTILITIES
 */
export default {

    drawMooringBittGroupFromBitts(group, bitts) {
        if (bitts && bitts.length > 1) {
            // Need at least two bitts to draw a line
            let extent = [];
            bitts.forEach((bitt) => {
                let location = wkt.parse(bitt.map_geom);
                extent.push(location.coordinates);
            });
            let line = turf.lineString(extent);
            let buf = turf.buffer(line, 0.0001 * 50, {
                units: 'kilometers',
            });
            return new maptalks.Polygon(buf.geometry.coordinates[0], {
                properties: {
                    'code': group.code,
                    'name': group.name,
                },
                symbol: this.getMooringBittGroupStyle()
            });
        }

    },

    getMooringBittGroupStyle() {
        return {
            'lineColor': '#34495e',
            'lineWidth': 1,
            'polygonFill': 'rgb(255,255,0)',
            'polygonOpacity': 0.2,
            'textFaceName': 'Nunito',
            'textName': '{code} - {name}', //value from name in geometry's properties
            'textWeight': 'bold', //'bold', 'bold'
            'textStyle': 'normal', //'italic', 'oblique'
            'textSize': {
                stops: [
                    [15, 0],
                    [16, 12]
                ]
            },
            'textOpacity': 1,
            'textLineSpacing': 0,
            'textDx': 0,
            'textDy': 25,
            'textHorizontalAlignment': 'middle', //left | middle | right | auto
            'textVerticalAlignment': 'middle', // top | middle | bottom | auto
            'textAlign': 'center', //left | right | center | auto,
            'textFill': '#34495e',
        }
    },
}