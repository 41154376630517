<template>
  <div>
    <v-dialog v-model="dialogOpen" persistent max-width="500">
      <v-card tile>
        <v-progress-linear
          height="10"
          striped
          color="lime"
          indeterminate
          v-show="loading"
        ></v-progress-linear>
        <v-card-title class="headline">{{
          $t("global.eliminate")
        }}</v-card-title>
        <v-card-text>{{ $t("global.eliminate_message") }}</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn depressed color="darken-1" text @click="cancelDelete">{{ $t('global.no') }}</v-btn>
          <v-btn depressed color="red darken-1" text @click="confirmDelete">{{ $t('global.yes') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/*eslint-disable*/
export default {
  props: ["item", "open"],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    dialogOpen: {
      // getter
      get: function() {
        return this.open;
      },
      // setter
      set: function(newValue) {
        this.$emit("update:open", newValue);
      },
    },
  },
  methods: {
    cancelDelete() {
      this.dialogOpen = false;
    },
    confirmDelete() {
      this.loading = true;
      this.$store
        .dispatch("moorings/DELETE_MOORING_BITT_GROUP", this.item.id)
        .then(() => {
          this.loading = false;
          this.dialogOpen = false;
        })
        .catch(() => {
          this.loading = false;
          this.dialogOpen = false;
        });
    },
  },
};
</script>
